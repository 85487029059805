import SiteStore from "../stores/SiteStore";
import { useStore } from "effector-react";

import Home from "./Home";

function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(" ");
}

function Main() {
    const site_store = useStore(SiteStore);
    return (
        <>
            <main className={classNames("bg-BG_IMAGE", "h-screen bg-cover")}>
                <div className="bg-gray-700 bg-opacity-50 h-screen">
                    <div className={classNames("h-screen bg-white", "xs:bg-transparent xs:pt-3", "sm:pt-8")}>
                        <div
                            className={classNames(
                                "w-full bg-white mx-auto",
                                "xs:w-11/12 xs:rounded-md",
                                "sm:w-8/12 sm:rounded-xl"
                            )}
                        >
                            {site_store.state === "INIT" && <Home />}
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default Main;
