import SiteStore, { extractContentField } from "../stores/SiteStore";
import { useStore } from "effector-react";

import RadioQuestion from "../components/RadioQuestion";

import React from "react";

function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(" ");
}

export default function Home() {
    const site_store = useStore(SiteStore);
    const renderHTML = (rawHTML: string) =>
        React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
    return (
        <>
            <div className={classNames("w-full p-3")}>
                <img
                    className={classNames("max-h-20 p-4")}
                    alt="logo"
                    src={`/media/${extractContentField(site_store, "LOGO")}`}
                />
            </div>
            <div className={classNames("w-full flex justify-center bg-red-600")}>
                <h1 className={classNames("mx-auto text-white text-3xl p-2")}>
                    {extractContentField(site_store, "TOP_BANNER")}
                </h1>
            </div>
            <div className={classNames("w-full flex justify-center text-center")}>
                <div className={classNames("mx-auto text-blue-700 text-4xl p-4 font-bold")}>
                    {renderHTML(extractContentField(site_store, "TITLE_TEXT")!)}
                </div>
            </div>
            <div className={classNames("w-full flex justify-center bg-red-600")}>
                <h1 className={classNames("mx-auto text-white text-3xl p-2")}>
                    {extractContentField(site_store, "BOTTOM_BANNER")}
                </h1>
            </div>
            <div className={classNames("w-full")}>
                <RadioQuestion questionIdx={0} />
            </div>
            <div className={classNames("w-full")}>
                <div className={classNames("mx-auto text-blue-700 text-xl p-4 font-bold")}>
                    {renderHTML(extractContentField(site_store, "CONTACT_FORM_TITLE")!)}
                </div>
            </div>
            <div className={classNames("w-full flex justify-center pb-8")}>
                <button className={classNames("bg-blue-700 rounded-xl text-white text-2xl w-11/12 py-6")}>
                    {extractContentField(site_store, "BUTTON_TEXT")}
                </button>
            </div>
        </>
    );
}
