import { Route, Switch } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

import SiteStore from "./stores/SiteStore";

import { useStore } from "effector-react";

import Main from "./pages/Main";
import ThankYou from "./pages/ThankYou";

function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(" ");
}

function App() {
    const site_store = useStore(SiteStore);
    return (
        <HelmetProvider>
            <Helmet>
                <title>Title Here</title>
            </Helmet>
            <main className={classNames("relative z-10")}>
                <Switch>
                    <Route path="/" component={Main} exact />
                    <Route path="/thankyou" component={ThankYou} />
                </Switch>
            </main>
        </HelmetProvider>
    );
}

export default App;
