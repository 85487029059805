import SiteStore, { updateSiteStore } from "../stores/SiteStore";
import { useStore } from "effector-react";

function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(" ");
}

function ThankYou() {
    const site_store = useStore(SiteStore);
    return (
        <>
            <div className={classNames("relative w-full")}>
                <div
                    className={classNames(
                        "relative bg-white p-2",
                        "sm:align-center sm:p-4 sm:w-2/3 sm:mx-auto sm:rounded-t-lg sm:mt-6"
                    )}
                >
                    ThankYou
                </div>
            </div>
        </>
    );
}

export default ThankYou;
