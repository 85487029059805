import SiteStore from "../stores/SiteStore";
import { useStore } from "effector-react";

import { Question } from "reliance-private-sitebuilder-schema/dist/API";

function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(" ");
}

export default function RadioQuestion(props: { questionIdx: Number }) {
    var site_store = useStore(SiteStore);
    var q: Question = site_store.site!.survey!.questions![String(props.questionIdx)]!;
    return (
        <>
            <div className={classNames("w-full p-3")}>
                <p className={classNames("text-black text-2xl")}>{q.description}</p>
            </div>
            <div className={classNames("flex space-x-4 p-3")}>
                {q.options?.map((o, idx) => (
                    <div key={idx} className={classNames("flex-1")}>
                        <button
                            className={classNames(
                                "w-full bg-blue-500 text-white font-bold p-2 border border-blue-700 whitespace-nowrap rounded"
                            )}
                            onClick={() => {}}
                        >
                            {o?.value}
                        </button>
                    </div>
                ))}
            </div>
        </>
    );
}
